
import { Store } from '@/main';
import { StoreHelper } from '@/store/StoreHelper';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';

@Component
class QRCodePopUp extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private headerContent?: string;
  @Prop() private imageUrl?: string;
  @Prop() private buttonText?: string;
  @Prop() private noteText?: string;
  @Prop() private fileName?: string;

  private localShowModalPopup = false;
  private localHeaderContent = '';
  private localImageUrl = '';
  private localButtonText = '';
  private fileDownload = require('js-file-download');
  private localNoteText = '';
  private localFileName = '';
  private storeHelper: StoreHelper = new StoreHelper(Store);

  private mounted() {
    // set props value in local variables
    this.localShowModalPopup = this.showModalPopup!;
    this.localHeaderContent = this.headerContent!;
    this.localImageUrl = this.imageUrl!;
    this.localButtonText = this.buttonText!;
    this.localNoteText = this.noteText!;
    this.localFileName = this.fileName!;
  }
  private Cancel() {
    this.$emit('close');
  }
  private DownLoadImage() {
    // download QR code image click of download image btn
    const a = document.createElement('a'); // create an anchor tag
    a.href = this.localImageUrl;
    a.download = 'QrCode_' + this.storeHelper.GetActiveEventNameFromStore() + '.png';
    a.target = '_blank';
    a.click();
  }
  @Watch('imageUrl')
  private CheckChangeInImageUrl(val: string) {
    this.localImageUrl = val;
  }
}
export default toNative(QRCodePopUp);
