
import { Component, toNative, Vue } from 'vue-facing-decorator';
import Toaster from '@/components/Common/Toaster.vue';
import loading from '@/components/Common/loading.vue';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { ChannelInfo } from '@/entities/Chat/ChannelInfo';
import ChatListing from './ChatListing.vue';
import ChatDetail from './ChatDetail.vue';
import { FetchData, Root, t } from '@/main';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
// import iframe
import VueIFrame from '@/components/IFrames/VueIFrame.vue';


@Component({
  components: {
    Toaster,
    loading,
    VueModalPopUp,
    ChatListing,
    ChatDetail,
    VueIFrame,
  },
})
class ChatTab extends Vue {
  // local variables
  private localChats: ChannelInfo[] = [];
  private selectedChatData: ChannelInfo = ChannelInfo.createEmpty();
  private addIcon = require('@/assets/Images/blue-add-icon.svg');
  private previewIcon = require('@/assets/Images/preview-icon.svg');
  private showChatDetailSection = false;
  private appId = 0;
  private eventId = 0;
  // Vue modal popup
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private channelIdToBeDeleted = 0;
  private toasterDefaultTimeout = 3000;
  private showInfoString = false;

  private mounted() {
    this.GetChannelData();
    Root.on('event-detail-dto', (val: any) => {
      this.appId = val.ApplicationId;
      this.eventId = val.Id;
    });
  }

  private SetModelPopUpMessage() {
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      this.$t('EventSetting.Chat.DeleteChannelPopUpHeading').toString(),
      this.$t('EventSetting.Chat.DeleteChannelPopUpDesciption').toString(),
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      this.$t('CancelButton').toString(),
      this.$t('DeleteButton').toString(),
    );
  }

  private ShowChatDetail(value: boolean) {
    Root.emit('disableTabs', value);
    this.selectedChatData = ChannelInfo.createEmpty();
    this.selectedChatData.AppId = this.appId;
    this.selectedChatData.EventId = this.eventId;
    this.showChatDetailSection = value;
  }
  private EditChannelDetail(value: any) {
    this.selectedChatData = this.localChats.filter((x) => x.Id === value)[0];
    this.selectedChatData.AppId = this.appId;
    this.selectedChatData.EventId = this.eventId;
    this.showChatDetailSection = true;
    Root.emit('disableTabs', true);
  }

  private DeleteChannel(value: any) {
    this.SetModelPopUpMessage();
    this.showVueModalPopUp = true;
    // get channel by id from list
    this.selectedChatData = this.localChats.filter((x) => x.Id === value)[0];
    this.channelIdToBeDeleted = value;
  }
  private CloseModelPopup() {
    this.showVueModalPopUp = false;
  }

  // get channel data hit api
  private GetChannelData() {
    FetchData('/ApplicationInstance/GetChannels','')
      .then((response: any) => {
        this.localChats = response;
      })
      .catch(() => {
        // Do nothing
      });
  }

  private DeleteModelPopUp() {
    this.showVueModalPopUp = false;
    Root.emit('show-loader', true);
    FetchData('/ApplicationInstance/DeleteChannel',
      JSON.stringify({
        Id: this.selectedChatData.Id,
        channelType: this.selectedChatData.Type,
      }))
      .then(() => {
        const toasterData = ShowToaster.createEmpty();
        toasterData.Message = t('EventSetting.Chat.ChannelDeletedSuccessfully').toString();
        toasterData.Val = true;
        Root.emit('show-toaster', toasterData);
        setTimeout(() => {
          toasterData.Val = false;
          Root.emit('show-toaster', toasterData);
          Root.emit('show-loader', false);
          this.localChats = this.localChats.filter((x) => x.Id !== this.selectedChatData.Id);
        }, this.toasterDefaultTimeout);
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  private RedirectToChat() {
    // redirect to url open in new tab
    window.open( process.env.VUE_APP_VUEAPP + '#/eventsetting/chat', '_blank');
  }
  // Used to show the information string
  private GetInformationString(val: boolean) {
    this.showInfoString = val;
  }
  // Used to check whether an event have any channel or not
  private CheckEmptyState() {
    return this.localChats !== null && this.localChats !== undefined && this.localChats.length !== 0;
  }
}


export default toNative(ChatTab);
