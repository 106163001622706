/* eslint-disable @typescript-eslint/no-shadow */
import ControlType from '@/enums/ControlType';
import { CardViewModel } from '../CardViewModel';
import { t } from '@/main';

export class EventVisibilityCards {
  public EventVisibilityCards: CardViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.EventVisibilityCards.push({ Id: 1, SortOrder: 1, IconUrl: require('@/assets/Images/public-event.svg'), Title: t('EventVisibility.Public').toString(), Description: t('EventVisibility.PublicDescription').toString(), IsSelected: false, IsDisabled: false, HelpText: t('EventVisibility.ReadMore').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/visibility', IsExternalLink: true, ControlType: ControlType.GreenTick , IsVisible: false});
    this.EventVisibilityCards.push({ Id: 2, SortOrder: 2, IconUrl: require('@/assets/Images/code-event.svg'), Title: t('EventVisibility.Code').toString(), Description: t('EventVisibility.CodeDescription').toString(), IsSelected: false, IsDisabled: false, HelpText: t('EventVisibility.ReadMore').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/visibility', IsExternalLink: true, ControlType: ControlType.GreenTick , IsVisible: true});
    this.EventVisibilityCards.push({ Id: 3, SortOrder: 3, IconUrl: require('@/assets/Images/require-tickets-event.svg'), Title: t('EventVisibility.RequiredTickets').toString(), Description: t('EventVisibility.RequiredTicketsDescription').toString(), IsSelected: false, IsDisabled: false, HelpText: t('EventVisibility.ReadMore').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/visibility', IsExternalLink: true, ControlType: ControlType.GreenTick , IsVisible: false});
  }
}

export class FeatureCards {
  public FeatureCards: CardViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.FeatureCards.push({ Id: 1, SortOrder: 1, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/program-icon.svg'), Title: t('Program.ProgramTab').toString(), Description: t('EventSetting.ProgramDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true});
    this.FeatureCards.push({ Id: 2, SortOrder: 2, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/my-program-icon.svg'), Title: t('EventSetting.MyProgram').toString(), Description: t('EventSetting.MyProgramDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: true, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 3, SortOrder: 3, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/information-icon.svg'), Title: t('Information').toString(), Description: t('EventSetting.InformationDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: true, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 4, SortOrder: 4, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/my-info-icon.svg'), Title: t('EventSetting.MyInformation').toString(), Description: t('EventSetting.MyInformationDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 5, SortOrder: 5, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/dialogue-icon.svg'), Title: t('Dialogue').toString(), Description: t('EventSetting.DialogueDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true });
    this.FeatureCards.push({ Id: 6, SortOrder: 6, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/participants-icon.svg'), Title: t('Participants').toString(), Description: t('EventSetting.ParticipantsDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 7, SortOrder: 7, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/live-streaming.svg'), Title: t('EventSetting.LiveStraeming').toString(), Description: t('EventSetting.LiveStreamingDescription').toString(), IsSelected: true, IsDisabled: true, HelpText:t('EventVisibility.ReadMore').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/watch-live', IsExternalLink: false, ControlType: ControlType.a , IsVisible: true });
    this.FeatureCards.push({ Id: 8, SortOrder: 8, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ventla-invite-icon.svg'), Title: t('EventSetting.VentlaInvite').toString(), Description: t('EventSetting.VentlaDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/ventla-invite', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true });
    this.FeatureCards.push({ Id: 9, SortOrder: 9, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/activity-feed-icon.svg'), Title: t('ActivityFeed').toString(), Description: t('EventSetting.ActivityFeedDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 10, SortOrder: 10, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/chat-icon.svg'), Title: t('ChatAndDiscussions').toString(), Description: t('EventSetting.ChatDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 11, SortOrder: 11, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/image-gallery-icon.svg'), Title: t('Imagegallery').toString(), Description: t('EventSetting.ImageGalleryDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 12, SortOrder: 12, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/gamification-icon.svg'), Title: t('Gamification').toString(), Description: t('EventSetting.GamificationDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 13, SortOrder: 13, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/social-media-icon.svg'), Title: t('Socialmedia').toString(), Description: t('EventSetting.SocialMediaDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: false});
    this.FeatureCards.push({ Id: 15, SortOrder: 15, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/notes-icon.svg'), Title: t('EventSetting.Notes').toString(), Description: t('EventSetting.NotesDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: '', HelpTextUrl: 'https://www.ventla.io/knowledge', IsExternalLink: false, ControlType: ControlType.CheckBox  , IsVisible: true});
    this.FeatureCards.push({ Id: 14, SortOrder: 14, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/webpage-icon.svg'), Title: t('EventSetting.EventWebPages').toString(), Description: t('EventSetting.EventWebPageDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true });
    this.FeatureCards.push({ Id: 16, SortOrder: 16, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/self-check-in-icon.svg'), Title: t('Selfcheck-in').toString(), Description: t('EventSetting.SelfcheckInDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/self-check-in', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true, QRCodeData: {HeaderText: t('EventSetting.SelfCheckInHeading').toString(), ButtonText: t('EventSetting.DownloadAsImageButtonText').toString(), NoteText: '', QRCodeURL: '', FileName: t('EventSetting.SelfCheckInQrCodeImageName').toString(), ShowQRCode: false, Tooltip: t('EventSetting.ToolTip.SelfCheckInQRCodeDisable').toString()} });
    this.FeatureCards.push({ Id: 17, SortOrder: 17, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/ticket-icon.svg'), Title: t('EventSetting.ParticipantQRTickets').toString(), Description: t('EventSetting.ParticipantQRTicketsDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true });
    this.FeatureCards.push({ Id: 18, SortOrder: 18, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/web-app-icon.svg'), Title: t('EventSetting.WebApp').toString(), Description: t('EventSetting.WebAppDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io', IsExternalLink: false, ControlType: ControlType.CheckBox , IsVisible: true });
  }
}

export class IntegrationCards {
  public IntegrationCards: CardViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.IntegrationCards.push({ Id: 1, SortOrder: 1, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/zapier-logo.svg'), Title: '', Description: t('EventSetting.ZapierDescription').toString(), IsSelected: false, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io/kb/integrations/zapier', IsExternalLink: true, ControlType: ControlType.a , IsVisible: true, ReadMoreLink: 'https://support.ventla.io/kb/integrations/zapier'});
    this.IntegrationCards.push({ Id: 2, SortOrder: 2, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/Eventbrite_Logo.svg'), Title: '', Description: t('EventSetting.EventBrightDescription').toString(), IsSelected: true, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/eventbrite', IsExternalLink: true, ControlType: ControlType.a , IsVisible: true});
    this.IntegrationCards.push({ Id: 3, SortOrder: 3, IconUrl: require('@/assets/Images/EventSettingFeaturesAndIntegrationImages/lyyti_logo_green.svg'), Title: '', Description: t('EventSetting.LyytiDescription').toString(), IsSelected: false, IsDisabled: false, HelpText: t('EventSetting.Tutorial').toString(), HelpTextUrl: 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/lyyti', IsExternalLink: true, ControlType: ControlType.a , IsVisible: false});
  }
}
