export class QRCodePopupViewModel {
  public static createEmpty(): QRCodePopupViewModel {
    return new QRCodePopupViewModel();
  }
  constructor(
    public HeaderText: string = '',
    public ButtonText: string = '',
    public QRCodeURL: string = '',
    public NoteText: string = '',
    public FileName: string = '',
    public ShowQRCode: boolean = false,
    public Tooltip: string = '',
  ) {}
}
