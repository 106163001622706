import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "parent-div-feature background-white" }
const _hoisted_2 = { class: "feature-heading" }
const _hoisted_3 = { class: "primary-heading" }
const _hoisted_4 = { class: "div-feature" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "display-flex" }
const _hoisted_8 = { class: "width98 custom-hr" }
const _hoisted_9 = {
  key: 1,
  class: "parent-div-integration"
}
const _hoisted_10 = { class: "copy-link-message" }
const _hoisted_11 = { class: "alert alert-success show-copied-message" }
const _hoisted_12 = { class: "parent-div-integration background-white" }
const _hoisted_13 = { class: "integrations-heading" }
const _hoisted_14 = { class: "primary-heading" }
const _hoisted_15 = { class: "div-integration" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = ["id"]
const _hoisted_18 = { class: "footer-tab border-radius-20 margin-top-20" }
const _hoisted_19 = { class: "parent-div-buttons" }
const _hoisted_20 = ["href", "disabled"]
const _hoisted_21 = { class: "" }
const _hoisted_22 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRCodePopUp = _resolveComponent("QRCodePopUp")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["feature-and-integration-tab", { 'disabledpage': _ctx.isScreenDisable }]),
    onKeypress: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args))),
    onMousemove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.IsObjectDirty && _ctx.IsObjectDirty(...args)))
  }, [
    (_ctx.qrCodeData.ShowQRCode)
      ? (_openBlock(), _createBlock(_component_QRCodePopUp, {
          key: 0,
          "show-modal-popup": _ctx.qrCodeData.ShowQRCode,
          "header-content": _ctx.qrCodeData.HeaderText,
          "image-url": _ctx.qrCodeData.QRCodeURL,
          "button-text": _ctx.qrCodeData.ButtonText,
          "note-text": _ctx.qrCodeData.NoteText,
          "file-name": _ctx.qrCodeData.FileName,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseQRCodePopup()))
        }, null, 8, ["show-modal-popup", "header-content", "image-url", "button-text", "note-text", "file-name"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("EventSetting.FeatureTab.MenuItemAndFeatures")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.showFeature)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.PasslocalFeatureCards(), (localFeatureCard, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: 'divFeatureCard' + localFeatureCard.Id,
                  key: localFeatureCard.Id,
                  class: _normalizeClass({ 'action-content-width-left': _ctx.IsEvenIndex(index)
                      ,'action-content-width-right': !_ctx.IsEvenIndex(index),
                      'display-none': !localFeatureCard.IsVisible,
                      'inner-content': localFeatureCard.IsVisible}
            )
                }, [
                  _createVNode(_component_Card, {
                    "event-visibility-card": localFeatureCard,
                    onSelectedCardValue: _ctx.ChangeFeatureValue
                  }, null, 8, ["event-visibility-card", "onSelectedCardValue"])
                ], 10, _hoisted_6))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("hr", {
              class: _normalizeClass({'margin-bottom-10': _ctx.isMobileView})
            }, null, 2)
          ])
        ])
      ])
    ]),
    (_ctx.showCopiedMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "integrations-heading" }, null, -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _cache[6] || (_cache[6] = _createElementVNode("i", {
                    class: "fa fa-check",
                    "aria-hidden": "true"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("KeyCopiedSuccessfully")), 1)
                ])
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("EventSetting.Integrations")), 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        (_ctx.showFeature)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localIntegrationCards, (localIntegrationCard, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  id: 'divIntegrationCard' + localIntegrationCard.Id,
                  key: localIntegrationCard.Id,
                  class: _normalizeClass(["inner-content", 
              _ctx.IsEvenIndex(index) == true
                ? 'action-content-width-left'
                : 'action-content-width-right'
            ])
                }, [
                  _createVNode(_component_Card, {
                    "event-visibility-card": localIntegrationCard,
                    onSelectedCardValue: _ctx.ChangeEventType,
                    onShowCopiedKey: _ctx.ShowCopiedKey
                  }, null, 8, ["event-visibility-card", "onSelectedCardValue", "onShowCopiedKey"])
                ], 10, _hoisted_17))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "display-flex" }, [
        _createElementVNode("div", { class: "width100" }, [
          _createElementVNode("hr", { class: "footer-hr margin-top-0" })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            href: 
              _ctx.$EnvironmentBaseURL +
                '/Admin/ApplicationInstance/DetailsApplicationInstance/1'
            ,
            disabled: _ctx.isDirtyObject,
            class: "btn btn-secondary btn-size-default btn-cancelevent",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Discard()))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
          ], 8, _hoisted_20)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            disabled: _ctx.isDirtyObject,
            class: "btn btn-primary btn-size-default width100 btn-create-event",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit()))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ], 8, _hoisted_22)
        ])
      ])
    ])
  ], 34))
}