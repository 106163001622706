enum FeatureModuleEnum  {
  Program = 1,
  MyProgram = 2,
  Information = 3,
  MyInformation = 4,
  Dialogue = 5,
  Participants = 6,
  LiveStreaming = 7,
  VentlaInvite = 8,
  ActivityFeed = 9,
  Chat = 10,
  ImageGallery = 11,
  Gamification = 12,
  SocialMedia = 13,
  EventWebPages = 14,
  Notes = 15,
  SelfCheckIn = 16,
  PartcipantQR = 17,
  WebApp = 18,
}
export default Object.freeze(FeatureModuleEnum);
